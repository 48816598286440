@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '@amzn/katal-components/styles.css';

html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0px;
  overflow: auto;
  height: 100%;
}

.landing-page-content {
  .benefits-and-features-container {
    div[class^='awsui_child'] {
      flex: 1 1 45%;
    }
  }
}

.react-player-container {
  .react-player {
    margin: 5px 0px 5px 0px;
  }
}

div[class^='awsui_drawer-content'] {
  top: 50px !important;
  display: flex !important;
  align-items: start !important;
  height: 100% !important;
  position: static !important;
}

// Used for most common tables
.dmm-table {
  height: 100%;
  font-size: small !important;
}

.dmm-split-panel {
  div[class^='awsui_content-side'] {
    padding-bottom: 100px !important;
  }
}

.form-field-select-width-30rem {
  width: 30rem;
}

.form-field-select-width-20rem {
  width: 20rem;
}

.form-field-select-width-15rem {
  width: 15rem;
}

.form-field-select-width-12rem {
  width: 12rem;
}

.form-field-select-width-10rem {
  width: 10rem;
}

.form-field-select-width-8rem {
  width: 8rem;
}

.form-field-select-width-6rem {
  width: 6rem;
}

.form-field-select-width-5rem {
  width: 5rem;
}

.quicksight-dashboard-frame {
  width: 100%;
  height: 77vh;
}

.custom-tooltip {
  width: 200px;
  min-height: 70px;
  border: 1px solid cornflowerblue;
  overflow-wrap: break-word;
}

.custom-tooltip p {
  margin: 5px;
  white-space: pre-wrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.ag-theme-alpine {
  --ag-header-height: 30px;
}

.ag-theme-alpine .ag-header-group-cell {
  font-size: 10px;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 10px;
}

div.ag-theme-alpine div.ag-header div.ag-row div.ag-cell {
  font-size: 10px !important;
}

.tutorial-link-container {
  width: 240px !important;
  height: 219px !important;

  .tutorial-link-img-thumbnail {
    border-radius: 10px !important;
  }

  .click-top-play {
    cursor: pointer;
  }
}

.video-container {
  height: 720px !important;
  border-radius: 0% !important;
  .video-frame {
    height: 720px !important;
    border-radius: 10px !important;
  }
}

.forecast-details-selection-container {
  min-height: 130px;
}

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

.ag-header-cell {
    font-size: 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.read-only-cell {
    background-color: #ebeae8;
}

.nan-cell {
    color: black;
    background-color: lightcoral;
}

div.ag-theme-alpine div.ag-row {
    font-size: 12px !important;
}

// .ag-cell {
//     padding-left: 5px !important;
//     padding-right: 5px !important;
// }